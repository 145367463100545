/* ==========================================================================
   Footer to bottom
   ========================================================================== */

html, body {
    height: 100%;
}

.page-wrapper,
.page {
    min-height: 100%;
}

.footer {
    padding: 55px 0;
    color: $color-grey-light;

    @media (max-width: $screen-sm) {
        padding: 30px 0;
    }

    &__logo {
        margin: -22px 0 0;

        @media (max-width: $screen-sm) {
            margin: 0;
        }
    }
}

.logo-violet {
    @include sprite($logo-violet);
    display: inline-block;
    border: 0 none;
}

.copyrights {

    @media (max-width: $screen-sm) {

        text-align: center;
        padding: 0 0 30px;
    }
}

.develop {
    text-align: right;

    @media (max-width: $screen-sm) {
        text-align: center;
        padding: 30px 0 0;
    }
}