.we-do {
    padding: 0 0 48px;

    &__header {
        padding: 49px 0 43px;
    }
}

.we-do {

    // animations
    .not-animated-section {

        .js-item {
            visibility: hidden;
        }
    }

    .animated-section {

        .animated-item {
            animation: zoomInRight 1000ms;
            visibility: visible;
        }
    }
}