.office-swiper {

    &,
    .swiper-slide {
        height: 650px;


        @media (max-width: $screen-lg) {
            height: 550px;
        }

        @media (max-width: $screen-md) {
            height: 400px;
        }

        @media (max-width: $screen-md) {
            height: 250px;
        }
    }
}

.carousel-slide {
    position: relative;

    &__image {
        @include absoluteTL(0, 0);
        @include block(100%, 100%);
        z-index: 1;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }

    &__header {
        @include absoluteTL(0, 0);
        @include block(520px, auto);
        z-index: 2;
        color: $color-white;
        font: 600 48px/1.25 $font-bitter;
        padding: 52px 0 0 11px;

        @media (max-width: $screen-md) {
            font-size: 34px;
        }

        @media (max-width: $screen-md) {
            font-size: 22px;
            padding: 20px 0 0 20px;
        }
    }
}