@import "../bootstrap/bootstrap/variables";
@import "sprite";
@import "mixins";

@import "blocks/career";
@import "blocks/about";
@import "blocks/office-swiper";
@import "blocks/icons-list";
@import "blocks/video";
@import "blocks/why";
@import "blocks/stats";
@import "blocks/we-do";
@import "blocks/num-list";
@import "blocks/pictures";
@import "blocks/salary";
@import "blocks/footer";
@import "blocks/popup";

/* ==========================================================================
   Vertical middle align
   ========================================================================== */
.vertical-middle {
    display: table;

    &,
    & > div {
        height: 100%;
        width: 100%;
        vertical-align: middle;
    }

    & > div {
        display: table-cell;
    }
}

/* ==========================================================================
   JS
   ========================================================================== */
.js-form {
    position: relative;

    .js-loader,
    .js-success,
    .js-error {
        display: none;
    }
}


/* ==========================================================================
   Bootstrap debug
   ========================================================================== */
.debug {
	position: fixed;
	top: 0;
	left: 0;
	background: #0c0;
	z-index: 10000;
}

.debug-box {
	@include block(100%, 200px);
	background: transparentize(#00c, .8);
}