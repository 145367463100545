.stats {
    padding: 73px 0;
    @include gradient(#58388b, #8653b9);
    background: url('../images/star-dust.jpg') 50% 50% no-repeat;
    background-size: cover;
    color: $color-white;

    @media (max-width: $screen-sm) {
        padding: 50px 0 0;
    }
}

.stat-item {
    text-align: center;
    padding: 56px 0 0;

    @media (max-width: $screen-sm) {
        margin-bottom: 50px;
    }

    &__icon {
        @include absoluteTL(0, 0);
        width: 100%;
        text-align: center;

        &--tall {
            top: -10px;
        }

        &--right {
            padding-left: 45px;
        }
    }

    &__num {
        font: 600 48px/1 $font-bitter;

        @media (max-width: $screen-md) {
            font-size: 36px;
        }
    }
}

.stats {

    // animations
    .not-animated-section {

        .js-item {

            &.icon__fly {
                visibility: hidden;
                transition: all 500ms;
                transform: translateX(-10px) translateY(100px);
            }

            &.icon__super-heroes {
                visibility: hidden;
            }
        }
    }

    .animated-section {

        .animated-item {

            &.icon__muscle {
                animation: swing 500ms;
            }

            &.icon__fly {
                visibility: visible;
                transform: translateX(0) translateY(0);
            }

            &.icon__stars-like {
                animation: bounce 500ms;
            }

            &.icon__super-heroes {
                visibility: visible;
                animation: zoomInUp 500ms;
            }
        }
    }
}
