.play {
    @include circle(120px);
    background: rgba($color-violet, .8);
    display: block;
    border: 0 none;
    @include absoluteTL(50%, 50%);
    margin: -60px 0 0 -60px;
    @include transition(500ms);
    position: relative;
    animation: pulse 800ms infinite;

    &:hover {
        opacity: .8;
    }

    &:after {
        @include sprite($play);
        display: block;
        content: '';
        @include absoluteTL(50%, 50%);
        margin-top: -$play-height / 2;
        margin-left: -$play-width / 2 + 4px;
    }
}

.video {
    position: relative;

    &__preview {
        @include block(100%, 100%);
    }

    &__play {
        @include absoluteTL(50%, 50%);
        margin: -60px 0 0 -60px;
   }
}