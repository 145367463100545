.pictures {

    &__item {
        float: left;
        width: 20%;

        @media (max-width: $screen-sm) {
            width: 50%;
        }
    }
}

.picture-item {
    height: 270px;


    @media (max-width: $screen-lg) {
        height: 230px;
    }

    @media (max-width: $screen-md) {
        height: 160px;
    }

    img {
        @include block(100%, 100%);
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }
}