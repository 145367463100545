// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$adlaim-name: 'adlaim';
$adlaim-x: 759px;
$adlaim-y: 319px;
$adlaim-offset-x: -759px;
$adlaim-offset-y: -319px;
$adlaim-width: 22px;
$adlaim-height: 26px;
$adlaim-total-width: 796px;
$adlaim-total-height: 740px;
$adlaim-image: '../images/sprite.png';
$adlaim: (759px, 319px, -759px, -319px, 22px, 26px, 796px, 740px, '../images/sprite.png', 'adlaim', );
$air-name: 'air';
$air-x: 393px;
$air-y: 164px;
$air-offset-x: -393px;
$air-offset-y: -164px;
$air-width: 205px;
$air-height: 204px;
$air-total-width: 796px;
$air-total-height: 740px;
$air-image: '../images/sprite.png';
$air: (393px, 164px, -393px, -164px, 205px, 204px, 796px, 740px, '../images/sprite.png', 'air', );
$arrow-2-name: 'arrow-2';
$arrow-2-x: 603px;
$arrow-2-y: 329px;
$arrow-2-offset-x: -603px;
$arrow-2-offset-y: -329px;
$arrow-2-width: 70px;
$arrow-2-height: 28px;
$arrow-2-total-width: 796px;
$arrow-2-total-height: 740px;
$arrow-2-image: '../images/sprite.png';
$arrow-2: (603px, 329px, -603px, -329px, 70px, 28px, 796px, 740px, '../images/sprite.png', 'arrow-2', );
$arrow-dots-name: 'arrow-dots';
$arrow-dots-x: 55px;
$arrow-dots-y: 690px;
$arrow-dots-offset-x: -55px;
$arrow-dots-offset-y: -690px;
$arrow-dots-width: 50px;
$arrow-dots-height: 50px;
$arrow-dots-total-width: 796px;
$arrow-dots-total-height: 740px;
$arrow-dots-image: '../images/sprite.png';
$arrow-dots: (55px, 690px, -55px, -690px, 50px, 50px, 796px, 740px, '../images/sprite.png', 'arrow-dots', );
$arrow-name: 'arrow';
$arrow-x: 0px;
$arrow-y: 562px;
$arrow-offset-x: 0px;
$arrow-offset-y: -562px;
$arrow-width: 114px;
$arrow-height: 123px;
$arrow-total-width: 796px;
$arrow-total-height: 740px;
$arrow-image: '../images/sprite.png';
$arrow: (0px, 562px, 0px, -562px, 114px, 123px, 796px, 740px, '../images/sprite.png', 'arrow', );
$cloud-name: 'cloud';
$cloud-x: 393px;
$cloud-y: 0px;
$cloud-offset-x: -393px;
$cloud-offset-y: 0px;
$cloud-width: 295px;
$cloud-height: 159px;
$cloud-total-width: 796px;
$cloud-total-height: 740px;
$cloud-image: '../images/sprite.png';
$cloud: (393px, 0px, -393px, 0px, 295px, 159px, 796px, 740px, '../images/sprite.png', 'cloud', );
$desk-name: 'desk';
$desk-x: 693px;
$desk-y: 190px;
$desk-offset-x: -693px;
$desk-offset-y: -190px;
$desk-width: 90px;
$desk-height: 90px;
$desk-total-width: 796px;
$desk-total-height: 740px;
$desk-image: '../images/sprite.png';
$desk: (693px, 190px, -693px, -190px, 90px, 90px, 796px, 740px, '../images/sprite.png', 'desk', );
$docs-name: 'docs';
$docs-x: 110px;
$docs-y: 690px;
$docs-offset-x: -110px;
$docs-offset-y: -690px;
$docs-width: 50px;
$docs-height: 50px;
$docs-total-width: 796px;
$docs-total-height: 740px;
$docs-image: '../images/sprite.png';
$docs: (110px, 690px, -110px, -690px, 50px, 50px, 796px, 740px, '../images/sprite.png', 'docs', );
$fly-name: 'fly';
$fly-x: 693px;
$fly-y: 285px;
$fly-offset-x: -693px;
$fly-offset-y: -285px;
$fly-width: 61px;
$fly-height: 103px;
$fly-total-width: 796px;
$fly-total-height: 740px;
$fly-image: '../images/sprite.png';
$fly: (693px, 285px, -693px, -285px, 61px, 103px, 796px, 740px, '../images/sprite.png', 'fly', );
$gear-check-name: 'gear-check';
$gear-check-x: 693px;
$gear-check-y: 567px;
$gear-check-offset-x: -693px;
$gear-check-offset-y: -567px;
$gear-check-width: 50px;
$gear-check-height: 50px;
$gear-check-total-width: 796px;
$gear-check-total-height: 740px;
$gear-check-image: '../images/sprite.png';
$gear-check: (693px, 567px, -693px, -567px, 50px, 50px, 796px, 740px, '../images/sprite.png', 'gear-check', );
$gear-head-name: 'gear-head';
$gear-head-x: 693px;
$gear-head-y: 622px;
$gear-head-offset-x: -693px;
$gear-head-offset-y: -622px;
$gear-head-width: 50px;
$gear-head-height: 50px;
$gear-head-total-width: 796px;
$gear-head-total-height: 740px;
$gear-head-image: '../images/sprite.png';
$gear-head: (693px, 622px, -693px, -622px, 50px, 50px, 796px, 740px, '../images/sprite.png', 'gear-head', );
$gift-name: 'gift';
$gift-x: 603px;
$gift-y: 219px;
$gift-offset-x: -603px;
$gift-offset-y: -219px;
$gift-width: 50px;
$gift-height: 50px;
$gift-total-width: 796px;
$gift-total-height: 740px;
$gift-image: '../images/sprite.png';
$gift: (603px, 219px, -603px, -219px, 50px, 50px, 796px, 740px, '../images/sprite.png', 'gift', );
$graph-2-name: 'graph-2';
$graph-2-x: 119px;
$graph-2-y: 616px;
$graph-2-offset-x: -119px;
$graph-2-offset-y: -616px;
$graph-2-width: 370px;
$graph-2-height: 19px;
$graph-2-total-width: 796px;
$graph-2-total-height: 740px;
$graph-2-image: '../images/sprite.png';
$graph-2: (119px, 616px, -119px, -616px, 370px, 19px, 796px, 740px, '../images/sprite.png', 'graph-2', );
$graph-3-name: 'graph-3';
$graph-3-x: 0px;
$graph-3-y: 445px;
$graph-3-offset-x: 0px;
$graph-3-offset-y: -445px;
$graph-3-width: 370px;
$graph-3-height: 112px;
$graph-3-total-width: 796px;
$graph-3-total-height: 740px;
$graph-3-image: '../images/sprite.png';
$graph-3: (0px, 445px, 0px, -445px, 370px, 112px, 796px, 740px, '../images/sprite.png', 'graph-3', );
$house-name: 'house';
$house-x: 693px;
$house-y: 95px;
$house-offset-x: -693px;
$house-offset-y: -95px;
$house-width: 100px;
$house-height: 90px;
$house-total-width: 796px;
$house-total-height: 740px;
$house-image: '../images/sprite.png';
$house: (693px, 95px, -693px, -95px, 100px, 90px, 796px, 740px, '../images/sprite.png', 'house', );
$kitchen-name: 'kitchen';
$kitchen-x: 693px;
$kitchen-y: 0px;
$kitchen-offset-x: -693px;
$kitchen-offset-y: 0px;
$kitchen-width: 103px;
$kitchen-height: 90px;
$kitchen-total-width: 796px;
$kitchen-total-height: 740px;
$kitchen-image: '../images/sprite.png';
$kitchen: (693px, 0px, -693px, 0px, 103px, 90px, 796px, 740px, '../images/sprite.png', 'kitchen', );
$leaders-name: 'leaders';
$leaders-x: 693px;
$leaders-y: 512px;
$leaders-offset-x: -693px;
$leaders-offset-y: -512px;
$leaders-width: 50px;
$leaders-height: 50px;
$leaders-total-width: 796px;
$leaders-total-height: 740px;
$leaders-image: '../images/sprite.png';
$leaders: (693px, 512px, -693px, -512px, 50px, 50px, 796px, 740px, '../images/sprite.png', 'leaders', );
$logo-violet-name: 'logo-violet';
$logo-violet-x: 324px;
$logo-violet-y: 562px;
$logo-violet-offset-x: -324px;
$logo-violet-offset-y: -562px;
$logo-violet-width: 200px;
$logo-violet-height: 49px;
$logo-violet-total-width: 796px;
$logo-violet-total-height: 740px;
$logo-violet-image: '../images/sprite.png';
$logo-violet: (324px, 562px, -324px, -562px, 200px, 49px, 796px, 740px, '../images/sprite.png', 'logo-violet', );
$logo-white-name: 'logo-white';
$logo-white-x: 119px;
$logo-white-y: 562px;
$logo-white-offset-x: -119px;
$logo-white-offset-y: -562px;
$logo-white-width: 200px;
$logo-white-height: 49px;
$logo-white-total-width: 796px;
$logo-white-total-height: 740px;
$logo-white-image: '../images/sprite.png';
$logo-white: (119px, 562px, -119px, -562px, 200px, 49px, 796px, 740px, '../images/sprite.png', 'logo-white', );
$medal-name: 'medal';
$medal-x: 603px;
$medal-y: 164px;
$medal-offset-x: -603px;
$medal-offset-y: -164px;
$medal-width: 50px;
$medal-height: 50px;
$medal-total-width: 796px;
$medal-total-height: 740px;
$medal-image: '../images/sprite.png';
$medal: (603px, 164px, -603px, -164px, 50px, 50px, 796px, 740px, '../images/sprite.png', 'medal', );
$muscle-name: 'muscle';
$muscle-x: 693px;
$muscle-y: 457px;
$muscle-offset-x: -693px;
$muscle-offset-y: -457px;
$muscle-width: 62px;
$muscle-height: 50px;
$muscle-total-width: 796px;
$muscle-total-height: 740px;
$muscle-image: '../images/sprite.png';
$muscle: (693px, 457px, -693px, -457px, 62px, 50px, 796px, 740px, '../images/sprite.png', 'muscle', );
$percent-name: 'percent';
$percent-x: 603px;
$percent-y: 274px;
$percent-offset-x: -603px;
$percent-offset-y: -274px;
$percent-width: 50px;
$percent-height: 50px;
$percent-total-width: 796px;
$percent-total-height: 740px;
$percent-image: '../images/sprite.png';
$percent: (603px, 274px, -603px, -274px, 50px, 50px, 796px, 740px, '../images/sprite.png', 'percent', );
$phone-name: 'phone';
$phone-x: 588px;
$phone-y: 373px;
$phone-offset-x: -588px;
$phone-offset-y: -373px;
$phone-width: 50px;
$phone-height: 50px;
$phone-total-width: 796px;
$phone-total-height: 740px;
$phone-image: '../images/sprite.png';
$phone: (588px, 373px, -588px, -373px, 50px, 50px, 796px, 740px, '../images/sprite.png', 'phone', );
$play-name: 'play';
$play-x: 748px;
$play-y: 512px;
$play-offset-x: -748px;
$play-offset-y: -512px;
$play-width: 39px;
$play-height: 49px;
$play-total-width: 796px;
$play-total-height: 740px;
$play-image: '../images/sprite.png';
$play: (748px, 512px, -748px, -512px, 39px, 49px, 796px, 740px, '../images/sprite.png', 'play', );
$star-shadow-name: 'star-shadow';
$star-shadow-x: 759px;
$star-shadow-y: 285px;
$star-shadow-offset-x: -759px;
$star-shadow-offset-y: -285px;
$star-shadow-width: 20px;
$star-shadow-height: 29px;
$star-shadow-total-width: 796px;
$star-shadow-total-height: 740px;
$star-shadow-image: '../images/sprite.png';
$star-shadow: (759px, 285px, -759px, -285px, 20px, 29px, 796px, 740px, '../images/sprite.png', 'star-shadow', );
$stars-like-name: 'stars-like';
$stars-like-x: 693px;
$stars-like-y: 393px;
$stars-like-offset-x: -693px;
$stars-like-offset-y: -393px;
$stars-like-width: 103px;
$stars-like-height: 59px;
$stars-like-total-width: 796px;
$stars-like-total-height: 740px;
$stars-like-image: '../images/sprite.png';
$stars-like: (693px, 393px, -693px, -393px, 103px, 59px, 796px, 740px, '../images/sprite.png', 'stars-like', );
$suitecase-name: 'suitecase';
$suitecase-x: 165px;
$suitecase-y: 690px;
$suitecase-offset-x: -165px;
$suitecase-offset-y: -690px;
$suitecase-width: 50px;
$suitecase-height: 50px;
$suitecase-total-width: 796px;
$suitecase-total-height: 740px;
$suitecase-image: '../images/sprite.png';
$suitecase: (165px, 690px, -165px, -690px, 50px, 50px, 796px, 740px, '../images/sprite.png', 'suitecase', );
$super-heroes-name: 'super-heroes';
$super-heroes-x: 393px;
$super-heroes-y: 373px;
$super-heroes-offset-x: -393px;
$super-heroes-offset-y: -373px;
$super-heroes-width: 190px;
$super-heroes-height: 61px;
$super-heroes-total-width: 796px;
$super-heroes-total-height: 740px;
$super-heroes-image: '../images/sprite.png';
$super-heroes: (393px, 373px, -393px, -373px, 190px, 61px, 796px, 740px, '../images/sprite.png', 'super-heroes', );
$super-man-small-name: 'super-man-small';
$super-man-small-x: 375px;
$super-man-small-y: 445px;
$super-man-small-offset-x: -375px;
$super-man-small-offset-y: -445px;
$super-man-small-width: 282px;
$super-man-small-height: 103px;
$super-man-small-total-width: 796px;
$super-man-small-total-height: 740px;
$super-man-small-image: '../images/sprite.png';
$super-man-small: (375px, 445px, -375px, -445px, 282px, 103px, 796px, 740px, '../images/sprite.png', 'super-man-small', );
$super-man-name: 'super-man';
$super-man-x: 0px;
$super-man-y: 0px;
$super-man-offset-x: 0px;
$super-man-offset-y: 0px;
$super-man-width: 388px;
$super-man-height: 440px;
$super-man-total-width: 796px;
$super-man-total-height: 740px;
$super-man-image: '../images/sprite.png';
$super-man: (0px, 0px, 0px, 0px, 388px, 440px, 796px, 740px, '../images/sprite.png', 'super-man', );
$swiper-left-name: 'swiper-left';
$swiper-left-x: 759px;
$swiper-left-y: 350px;
$swiper-left-offset-x: -759px;
$swiper-left-offset-y: -350px;
$swiper-left-width: 14px;
$swiper-left-height: 13px;
$swiper-left-total-width: 796px;
$swiper-left-total-height: 740px;
$swiper-left-image: '../images/sprite.png';
$swiper-left: (759px, 350px, -759px, -350px, 14px, 13px, 796px, 740px, '../images/sprite.png', 'swiper-left', );
$swiper-right-name: 'swiper-right';
$swiper-right-x: 778px;
$swiper-right-y: 350px;
$swiper-right-offset-x: -778px;
$swiper-right-offset-y: -350px;
$swiper-right-width: 14px;
$swiper-right-height: 13px;
$swiper-right-total-width: 796px;
$swiper-right-total-height: 740px;
$swiper-right-image: '../images/sprite.png';
$swiper-right: (778px, 350px, -778px, -350px, 14px, 13px, 796px, 740px, '../images/sprite.png', 'swiper-right', );
$talking-name: 'talking';
$talking-x: 0px;
$talking-y: 690px;
$talking-offset-x: 0px;
$talking-offset-y: -690px;
$talking-width: 50px;
$talking-height: 50px;
$talking-total-width: 796px;
$talking-total-height: 740px;
$talking-image: '../images/sprite.png';
$talking: (0px, 690px, 0px, -690px, 50px, 50px, 796px, 740px, '../images/sprite.png', 'talking', );
$teaching-name: 'teaching';
$teaching-x: 220px;
$teaching-y: 690px;
$teaching-offset-x: -220px;
$teaching-offset-y: -690px;
$teaching-width: 50px;
$teaching-height: 50px;
$teaching-total-width: 796px;
$teaching-total-height: 740px;
$teaching-image: '../images/sprite.png';
$teaching: (220px, 690px, -220px, -690px, 50px, 50px, 796px, 740px, '../images/sprite.png', 'teaching', );
$spritesheet-width: 796px;
$spritesheet-height: 740px;
$spritesheet-image: '../images/sprite.png';
$spritesheet-sprites: ($adlaim, $air, $arrow-2, $arrow-dots, $arrow, $cloud, $desk, $docs, $fly, $gear-check, $gear-head, $gift, $graph-2, $graph-3, $house, $kitchen, $leaders, $logo-violet, $logo-white, $medal, $muscle, $percent, $phone, $play, $star-shadow, $stars-like, $suitecase, $super-heroes, $super-man-small, $super-man, $swiper-left, $swiper-right, $talking, $teaching, );
$spritesheet: (796px, 740px, '../images/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
