.career,
.career-2,
.career-3 {

    // animations
    .not-animated-section {

        .js-item {
            visibility: hidden;
        }
    }

    .animated-section {

        li.animated-item {
            visibility: visible;
        }
    }
}


.career .animated-section li.animated-item {
    animation: bounceInDown 500ms;
}

.career-2 .animated-section li.animated-item {
    animation: fadeInLeft 500ms;
}

.career-3 .animated-section li.animated-item {
    animation: zoomInUp 500ms;
}