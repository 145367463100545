.about {
    background: $color-black url('../images/splash.jpg') 50% 50% no-repeat;
    background-size: cover;
    color: $color-white;
    padding: 0 0 49px;

    a {
        color: $color-white;
    }

    &__header {
        padding: 90px 0 36px;
    }

    &__video {
        @include block(755px, 425px);
        margin: 0 auto;

        @media (max-width: $screen-md) {
            width: 100%;
            height: auto;
            max-width: 755px;
        }
    }

    &__email {
        text-align: center;
        font-family: $font-bitter;
        font-size: 30px;
        padding: 46px 0;
    }
}