.icons-list {
    @include clear-list;
    padding: 0 0 0 42px;

    @media (max-width: $screen-md) {
        padding: 0;
    }

    li {
        @include clear-list;
        position: relative;
        padding: 10px 0 45px;

        @media (max-width: $screen-lg) {
            padding-bottom: 30px;
        }

        @media (max-width: $screen-md) {
            padding-left: 75px;
        }

        *[class*="icon__"] {
            position: absolute;
            top: 0;
            left: -70px;

            @media (max-width: $screen-md) {
                left: 0;
            }
        }
    }
}