.super-man-small {
    @include sprite($super-man-small);
    position: relative;

    &:before {
        @include sprite($cloud);
        content: '';
        @include absoluteTR(-60px, -$cloud-width);
        display: none;
    }
}

.salary {
    padding: 60px 0;

    &__header {
        padding-bottom: 45px;

        @media (max-width: $screen-sm) {
            padding-bottom: 0px;
        }
    }

    p.salary__comment {
        margin: 24px 0 27px;
    }

    &__button {
        text-align: center;
    }

    &__super-man {
        @include absoluteTR(37px, 183px);
        z-index: 10;

        @media (max-width: $screen-sm) {
            display: none;
        }
    }

    &__phone {
        text-align: center;
        font-size: 70px;
        padding: 40px 0;

        @media (max-width: $screen-xs) {
            padding: 20px 0;
            font-size: 40px;
        }

        a {
            color: $color-black;

            &, &:active, &:focus {
                text-decoration: none;
            }
        }
    }
}

.salary-item {
    padding: 51px 0 48px;
    text-align: center;
    color: $color-white;
    position: relative;

    @media (max-width: $screen-md) {
        padding: 30px 5px;
    }

    &--minimum {
        background: rgb(153,102,204);
        background: -moz-linear-gradient(left, rgba(153,102,204,1) 0%, rgba(167,88,162,1) 100%);
        background: -webkit-linear-gradient(left, rgba(153,102,204,1) 0%,rgba(167,88,162,1) 100%);
        background: linear-gradient(to right, rgba(153,102,204,1) 0%,rgba(167,88,162,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9966cc', endColorstr='#a758a2',GradientType=1 );
    }

    &--medium {
        background: rgb(169,86,157);
        background: -moz-linear-gradient(left, rgba(169,86,157,1) 0%, rgba(188,67,98,1) 100%);
        background: -webkit-linear-gradient(left, rgba(169,86,157,1) 0%,rgba(188,67,98,1) 100%);
        background: linear-gradient(to right, rgba(169,86,157,1) 0%,rgba(188,67,98,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a9569d', endColorstr='#bc4362',GradientType=1 );

        &:before {
            @include sprite($graph-2);
            display: block;
            content: '';
            @include absoluteTL(-$graph-2-height, 0);
        }
    }

    &--high {
        background: rgb(190,65,93);
        background: -moz-linear-gradient(left, rgba(190,65,93,1) 0%, rgba(204,51,51,1) 100%);
        background: -webkit-linear-gradient(left, rgba(190,65,93,1) 0%,rgba(204,51,51,1) 100%);
        background: linear-gradient(to right, rgba(190,65,93,1) 0%,rgba(204,51,51,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#be415d', endColorstr='#cc3333',GradientType=1 );

        &:before {
            @include sprite($graph-3);
            display: block;
            content: '';
            @include absoluteTL(-$graph-3-height, 0);
        }
    }

    &:before {
        width: 100% !important;

        @media (max-width: $screen-md) {
            display: none;
        }
    }

    &__title {
        font: 600 36px/1.33 $font-bitter;

        @media (max-width: $screen-lg) {
            font-size: 32px;
        }

        @media (max-width: $screen-md) {
            font-size: 24px;
        }

        @media (max-width: $screen-sm) {
            font-size: 28px;
        }
    }

    &__comment {
        padding: 13px 0;
    }

    &__salary {
        font: 600 60px/1 $font-bitter;

        @media (max-width: $screen-lg) {
            font-size: 50px;
        }

        @media (max-width: $screen-md) {
            font-size: 44px;
        }
    }
}

// animations
.salary {

    &.not-animated-section {

        .salary__super-man {
            transition: transform 1500ms, opacity 100ms;
            opacity: 0;
            transform: translateX(-700px);

            &.not-animated-item {}

            &.animated-item {
                opacity: 1;
                transform: translateX(0px);
            }
        }
    }
}
