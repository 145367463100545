.num-list {
    @include clear-list;
    padding: 0 0 0 42px;

    @media (max-width: $screen-md) {
        padding: 0;
    }

    li {
        @include clear-list;
        position: relative;
        padding: 10px 0 45px;
        min-height: 60px;

        @media (max-width: $screen-lg) {
            padding-bottom: 30px;
        }

        @media (max-width: $screen-md) {
            padding-left: 75px;
        }

        .num {
            @include circle(55px);
            display: block;
            background: $color-white;
            border: 5px solid $color-violet;
            text-align: center;
            font: 600 30px/52px $font-bitter;
            color: $color-violet;
            @include absoluteTL(0px, -70px);

            @media (max-width: $screen-md) {
                left: 0;
            }
        }
    }
}