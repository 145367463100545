/* ==========================================================================
   Mixins
   ========================================================================== */
@mixin clear-list() {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin block($width: 100%, $height: 100%) {
	width: $width;
	height: $height;
	display: block;
}

@mixin inline-block($width: 100%, $height: 100%) {
	@include block($width, $height);
	display: inline-block;
}
@mixin pic ($width, $height) {
    overflow: hidden;
    @include  block($width, $height);
}
@mixin circle($size) {
    width: $size;
    height: $size;
    overflow: hidden;
    border-radius: $size;
}
@mixin absoluteTL($top: 0, $left: 0) {
    position: absolute;
    left: $left;
    top: $top;
}
@mixin absoluteTR($top: 0, $right: 0) {
    position: absolute;
    right: $right;
    top: $top;
}
@mixin absoluteBL($bottom: 0, $left: 0) {
    position: absolute;
    left: $left;
    bottom: $bottom;
}
@mixin absoluteBR($bottom: 0, $right: 0) {
    position: absolute;
    right: $right;
    bottom: $bottom;
}
@mixin opacity($opacity: 100) {
    opacity: ($opacity / 100);
    filter: alpha(opacity=$opacity); //IE8
}
@mixin transition($time: 500ms) {
    transition: all $time ease;
}
@mixin gradient ($startColor, $endColor) {
    background-color: $startColor;
    background: linear-gradient(to bottom, $startColor 0%, $endColor 100%);
}

/* mixin for links with different color of underline decoration and text */
@mixin two-color-link($first-color, $second-color) {

    text-decoration: underline;
    color: $second-color;

    > span {
        color: $first-color;
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;

        > span {
            color: $second-color;
        }
    }

    &.with-icon {
        text-decoration: none;

        > span:first-child {
            text-decoration: underline;
            color: $second-color;

            > span {
                color: $first-color;
                text-decoration: none;
            }
        }

        &:hover {

            > span {
                text-decoration: none;
            }
        }
    }
}



//@mixin lg($rules) { @media (max-width: $screen-lg) { $rules; }}
/*@mixin md($rules) { @media (max-width: $screen-md) { $rules; }}
@mixin sm($rules) { @media (max-width: $screen-sm) { $rules; }}
@mixin xs($rules) { @media (max-width: $screen-xs) { $rules; }}
/*
.md-and-smaller (@rules) { @media (max-width: @screen-lg) { @rules(); }}
.sm-and-smaller (@rules) { @media (max-width: @screen-md) { @rules(); }}
.xs-and-smaller (@rules) { @media (max-width: @screen-sm) { @rules(); }}
*/
/* ==========================================================================
   Bootstrap mixins
   ========================================================================== */
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/clearfix";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/buttons";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/tab-focus";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins/vendor-prefixes";