.why {
    padding: 50px 0 53px;

    &__header {
        padding-bottom: 45px;
    }
}

.why-item {
    text-align: center;

    @media (max-width: $screen-sm) {
        margin-bottom: 30px;
    }

    &__icon {
        margin: 0 0 16px;

        @media (max-width: $screen-sm) {
            margin-bottom: 5px;
        }
    }
}

.why {

    // animations
    .not-animated-section {

        .js-item {
            visibility: hidden;
        }
    }

    .animated-section {

        .animated-item {
            animation: flipInX 1000ms;
            visibility: visible;
        }
    }
}